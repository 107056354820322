import { Box, Grid, TextField, Typography, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomFilterButton from "../../components/CustomFilterButton/CustomFilterButton";
import CustomHeader from "../../components/CustomHeader/CustomHeader";
import CustomRoundedCard from "../../components/CustomRoundedCard/CustomRoundedCard";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { APP_CONFIG } from "../../constants/config";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "10px",
  },
  header: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  dadosBox: {
    display: "flex",
    flexDirection: "row",

    marginTop: "100px",
    marginLeft: "30px",
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
  },
  contadorStyle: {
    display: "flex",
    fontSize: "30px",
    fontFamily: "Montserrat-SemiBold",
  },
}));
export default function ListaDeAdministradores() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.userData);
  const extrato = useSelector((state) => state.extrato);
  const token = useAuth();
  const [loading, setLoading] = useState(false);

  const columns = [
    { headerText: "Valor", key: "valor" },
    { headerText: "Nome", key: "nome" },
    { headerText: "Idade", key: "idade" },
  ];

  const itemColumns = [
    { headerText: "Valor", key: "valor" },
    { headerText: "Nome", key: "nome" },
    { headerText: "Idade", key: "idade" },
  ];

  const testeData = [{ valor: "teste", nome: "jose", idade: "32" }];

  return (
    <Box className={classes.root}>
      <LoadingScreen isLoading={loading} />

      <Box className={classes.main}>
        <CustomHeader />

        <Box className={classes.dadosBox}>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2} style={{ marginTop: "0px" }}>
              <Grid item sm={8} xs={12}>
                <Box
                  style={{
                    display: "flex",
                    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                    /* height: '100px', */
                    borderRadius: "17px",
                    flexDirection: "column",

                    /* alignItems: 'center', */
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat-ExtraBold",
                      fontSize: "16px",
                      color: APP_CONFIG.mainCollors.primary,
                      marginTop: "30px",
                      marginLeft: "40px",
                    }}
                  >
                    Extrato pix
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      style={{
                        width: "90%",
                        height: "1px",
                        backgroundColor: APP_CONFIG.mainCollors.primary,
                      }}
                    />
                    {/* <CustomCollapseTable
										data={testeData}
										columns={columns}
										itemColumns={itemColumns}
									/> */}
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <CustomFilterButton title="Lorem ipsum" />
                      <CustomFilterButton title="Lorem ipsum" />
                      <CustomFilterButton title="Lorem ipsum" />
                      <CustomFilterButton title="Lorem ipsum" />
                      <TextField
                        variant="outlined"
                        label=""
                        InputProps={{
                          endAdornment: (
                            <SearchIcon
                              style={{
                                fontSize: "25px",
                                color: APP_CONFIG.mainCollors.primary,
                              }}
                            />
                          ),
                        }}
                      />
                    </Box>
                    <Box
                      style={{
                        marginTop: "30px",
                        marginBottom: "30px",
                      }}
                    >
                      <CustomButton color="purple">
                        <Typography
                          style={{
                            fontFamily: "Montserrat-Regular",
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          Retirada
                        </Typography>
                      </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Box
                  style={{
                    display: "flex",
                    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                    /* height: '100px', */
                    borderRadius: "17px",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat-ExtraBold",
                      fontSize: "16px",
                      color: APP_CONFIG.mainCollors.primary,
                      marginTop: "30px",
                      marginLeft: "40px",
                    }}
                  >
                    Área pix
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      style={{
                        width: "90%",
                        height: "1px",
                        backgroundColor: APP_CONFIG.mainCollors.primary,
                      }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "10px",
                        }}
                      >
                        <CustomRoundedCard title="Receber" icon="receber" />
                        <CustomRoundedCard title="Pagar" icon="pagar" />
                        <CustomRoundedCard title="Chaves" icon="chaves" />
                      </Box>
                      <Box
                        style={{
                          display: "flex",

                          justifyContent: "space-around",
                          marginTop: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        <CustomRoundedCard
                          title="Aprovações"
                          icon="aprovacoes"
                        />
                        <CustomRoundedCard title="Extrato" icon="extrato" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

/* import {
	Box,
	Grid,
	LinearProgress,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import {
	getContasAction,
	getGraficoContaBarDashboardAction,
	getGraficoContaLineDashboardAction,
	getResumoContaDashboardAction,
	loadPermissao,
	postAuthMeAction,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomBarChart from '../../components/CustomBarChart/CustomBarChart';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomCard from '../../components/CustomCard/CustomCard';
import CustomLineChart from '../../components/CustomLineChart/CustomLineChart';
import CustomTable from '../../components/CustomTable/CustomTable';
import ItaDash4 from '../../assets/ItaDash4.svg';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',

		height: '100%',
	},
	headerContainer: {
		display: 'flex',
		width: '100%',

		flexDirection: 'column',
	},

	contadorStyle: {
		display: 'flex',
		fontSize: '30px',
		fontFamily: 'Montserrat-SemiBold',
	},

	pageTitle: {
		color: '#9D9CC6',
		fontFamily: 'Montserrat-SemiBold',
	},

	cardContainer: {
		display: 'flex',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
	},

	bodyContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		marginTop: '10px',
	},
}));

const columns = [
	{ headerText: 'Nome', key: 'nome' },
	{ headerText: 'Tipo', key: 'tipo' },
	{
		headerText: 'Status',
		key: 'status',
		CustomValue: (value) => {
			if (value === 'pending') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#F1E3D4',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: 'orange', width: '100%' }}>
								PENDENTE
							</Typography>
						</Box>
					</Box>
				);
			}
			if (value === 'active') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#C9DBF2',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: '#75B1ED', width: '100%' }}>
								ATIVO
							</Typography>
						</Box>
					</Box>
				);
			}
			if (value === 'enabled') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#C9DBF2',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: '#75B1ED', width: '100%' }}>
								ATIVO
							</Typography>
						</Box>
					</Box>
				);
			}
			if (value === 'approved') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#C9ECE7',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: '#00B57D', width: '100%' }}>
								APROVADO
							</Typography>
						</Box>
					</Box>
				);
			}
			if (value === 'denied') {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Box
							style={{
								borderRadius: 32,
								backgroundColor: '#ECC9D2',
								maxWidth: '120px',
								padding: '5px',
							}}
						>
							<Typography style={{ color: '#ED757D', width: '100%' }}>
								NEGADO
							</Typography>
						</Box>
					</Box>
				);
			}
		},
	},
	{ headerText: 'Número do Documento', key: 'numero_documento' },
	{ headerText: 'Documento', key: 'documento' },
];

const Dashboard = () => {
	const history = useHistory();
	const classes = useStyles();
	const token = useAuth();

	const [page, setPage] = useState(1);

	const dispatch = useDispatch();
	const contadores = useSelector((state) => state.contadores);

	useEffect(() => {
		dispatch(getResumoContaDashboardAction(token));
	}, []);

	const listaContas = useSelector((state) => state.contas);
	useEffect(() => {
		dispatch(
			getContasAction(
				token,
				page,

				'',
				'',
				5
			)
		);
	}, [token, page]);

	const handleVerTudo = () => {
		const path = generatePath('/dashboard/lista-de-contas');
		history.push(path);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.headerContainer}>
				<Box>
					<Typography className={classes.pageTitle}>DASHBOARD</Typography>
				</Box>

				<Box
					style={{
						display: 'flex',

						marginTop: '20px',
						marginLeft: '-12px',
					}}
				>
					<CustomCard
						text="Contas pendentes"
						
					>
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pendente}
							</Typography>
						</Box>
					</CustomCard>

					<CustomCard text="Contas aprovadas" aprovada>
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_aprovado}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Contas ativas">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_ativo}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Contas rejeitadas" rejeitada>
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_rejeitado}
							</Typography>
						</Box>
					</CustomCard>
				</Box>

				<Box
					style={{
						display: 'flex',

						marginLeft: '-12px',
					}}
				>
					<CustomCard text="Frequência diária">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.frequencia_quantidade_diaria}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Cadastro Pessoa Física">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pessoa_fisica}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Cadastro Pessoa Jurídica">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_pessoa_juridica}
							</Typography>
						</Box>
					</CustomCard>
					<CustomCard text="Total de Contas">
						<Box className={classes.cardContainer}>
							<Typography className={classes.contadorStyle}>
								{contadores.cadastro_total}
							</Typography>
						</Box>
					</CustomCard>
				</Box>
			</Box>
			<Box className={classes.bodyContainer}>
				<Box display="flex">
					<Grid container>
						<Grid xs={8}>
							<CustomLineChart />
						</Grid>
						<Grid xs={4}>
							<CustomBarChart />
						</Grid>
					</Grid>
				</Box>
				<Box display="flex" style={{ height: '100%', marginTop: '40px' }}>
					<Grid container>
						<Grid xs={12}>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',

									height: '75px',
									backgroundColor: APP_CONFIG.mainCollors.backgrounds,
									borderTopRightRadius: 27,
									borderTopLeftRadius: 27,
								}}
							>
								<Typography
									style={{
										color: '#9D9CC6',
										fontFamily: 'Montserrat-SemiBold',
										marginTop: '20px',
										alignSelf: 'center',
										marginLeft: '30px',
									}}
								>
									CONTAS RECENTES
								</Typography>

								<Box
									style={{
										marginTop: '20px',
										marginRight: '10px',
									}}
								>
									<CustomButton
										size="small"
										color="purple"
										onClick={handleVerTudo}
									>
										VER TUDO
									</CustomButton>
								</Box>
							</Box>
							<Box style={{ marginBottom: '40px', width: '100%' }}>
								{listaContas.data && listaContas.per_page ? (
									<CustomTable
										boxShadowTop={true}
										columns={columns}
										data={listaContas.data}
									/>
								) : null}
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export default Dashboard;
 */

/*
import '../../fonts/Montserrat-SemiBold.otf';

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	LinearProgress,
	Menu,
	MenuItem,
	TablePagination,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	delAdmin,
	delDocumento,
	getAllContasAction,
	getAprovarContaAction,
	getContasAction,
	getContasExportAction,
	getListaAdministradorAction,
	getReenviarTokenUsuarioAction,
	loadDocumentos,
	postCriarAdminAction,
} from '../../actions/actions';
import { generatePath, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../components/CustomButton/CustomButton';
import CustomSideBar from '../../components/CustomSideBar/CustomSideBar';
import CustomTable from '../../components/CustomTable/CustomTable';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { Pagination } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from '@material-ui/icons/ViewList';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	tableContainer: { marginTop: '1px' },
	pageTitle: {
		color: '#9D9CC6',
		fontFamily: 'Montserrat-SemiBold',
	},
}));

const columns = [
	{ headerText: 'E-mail', key: 'email' },

	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const date = new Date(data);
			const option = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			};
			const [dia] = date.toLocaleDateString('pt-br', option).split(' ');
			return <Typography align="center">{dia}</Typography>;
		},
	},

	{ headerText: '', key: 'menu' },
];

const ListaDeAdministradores = () => {
	const [email, setEmail] = useState('');
	const [open, setOpen] = useState(false);
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});
	const debouncedLike = useDebounce(filters.like, 800);
	const [loading, setLoading] = useState(false);
	const token = useAuth();
	const classes = useStyles();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			getListaAdministradorAction(
				token,
				page,

				debouncedLike,
				filters.order,
				filters.mostrar
			)
		);
	}, [page, debouncedLike, filters.order, filters.mostrar]);

	const listaAdministrador = useSelector((state) => state.listaAdministrador);

	const criarAdmin = async (e) => {
		e.preventDefault();

		const resCriarAdmin = await dispatch(postCriarAdminAction(token, email));
		if (resCriarAdmin) {
			toast.error('Administrador já criado');
		} else {
			toast.success('Token enviado com sucesso!');
		}
	};

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const Editar = (row) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const [disabled, setDisabled] = useState(false);

		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleExcluirAdmin = async (item) => {
			await dispatch(delAdmin(token, row.row.id));
		};

		const handlePermissions = () => {
			const path = generatePath(
				'/dashboard/lista-de-administradores/:id/permissoes',
				{
					id: row.row.id,
				}
			);
			history.push(path);
		};

		const handleReenviarTokenUsuario = async (row) => {
			setLoading(true);
			const resReenviarToken = await dispatch(
				getReenviarTokenUsuarioAction(token, row.row.id)
			);
			if (resReenviarToken === false) {
				setDisabled(true);
				toast.success('Reenviado com sucesso');
				setLoading(false);
			} else {
				toast.error('Falha ao reenviar');
				setLoading(false);
			}
		};

		return (
			<Box>
				<IconButton
					style={{ height: '15px', width: '10px' }}
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<SettingsIcon
						style={{
							borderRadius: 33,
							fontSize: '35px',
							backgroundColor: APP_CONFIG.mainCollors.primary,
							color: 'white',
						}}
					/>
				</IconButton>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem
						onClick={() => handlePermissions(row)}
						style={{ color: APP_CONFIG.mainCollors.secondary }}
					>
						Permissões
					</MenuItem>
					<MenuItem
						onClick={() => handleExcluirAdmin(row)}
						style={{ color: APP_CONFIG.mainCollors.secondary }}
					>
						Excluir
					</MenuItem>

					<MenuItem
						onClick={() => handleReenviarTokenUsuario(row)}
						style={{ color: APP_CONFIG.mainCollors.secondary }}
					>
						Reenviar Token de Confirmação
					</MenuItem>
				</Menu>
			</Box>
		);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.headerContainer}>
				<Box style={{ marginBottom: '20px' }}>
					<Typography className={classes.pageTitle}>
						Administradores
					</Typography>
				</Box>
				<Box
					style={{
						width: '100%',
						backgroundColor: APP_CONFIG.mainCollors.backgrounds,
						borderTopLeftRadius: 27,
						borderTopRightRadius: 27,
					}}
				>
					<Box
						display="flex"
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
						style={{ margin: 30 }}
					>
						<TextField
							placeholder="Pesquisar por nome, documento, email..."
							size="small"
							variant="outlined"
							style={{
								backgroundColor: APP_CONFIG.mainCollors.backgrounds,
								width: '400px',
							}}
							
							onChange={(e) => {
								setPage(1);
								setFilters({
									...filters,
									like: e.target.value,
								});
							}}
						></TextField>

						<Box>
							<CustomButton
								onClick={() => {
									setOpen(true);
								}}
							>
								<Box display="flex" alignItems="center">
									Criar Administrador
								</Box>
							</CustomButton>
						</Box>

						<Dialog
							open={open}
							onClose={() => {
								setOpen(false);
							}}
							aria-labelledby="form-dialog-title"
						>
							<DialogTitle id="form-dialog-title">
								Criar Administrador
							</DialogTitle>
							<form onSubmit={(e) => criarAdmin(e)}>
								<DialogContent>
									<DialogContentText>
										Para criar um administrador insira um e-mail.
										Enviaremos um token logo em seguida.
									</DialogContentText>

									<TextField
										onChange={(e) => setEmail(e.target.value)}
										autoFocus
										margin="dense"
										id="name"
										label="E-mail"
										type="email"
										fullWidth
									/>
								</DialogContent>
								<DialogActions>
									<Button
										onClick={() => {
											setOpen(false);
										}}
										color="primary"
									>
										Cancelar
									</Button>
									<Button
										onClick={() => {
											setOpen(false);
										}}
										color="primary"
										type="submit"
									>
										Enviar
									</Button>
								</DialogActions>
							</form>
						</Dialog>
					</Box>
				</Box>
			</Box>

			<Box className={classes.tableContainer}>
				{listaAdministrador.data && listaAdministrador.per_page ? (
					<CustomTable
						columns={columns}
						data={listaAdministrador.data}
						Editar={Editar}
					/>
				) : (
					<Box width="60vw">
						<LinearProgress color="secondary" />
					</Box>
				)}
				<Box
					display="flex"
					alignSelf="flex-end"
					marginTop="8px"
					justifyContent="space-between"
				>
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={listaAdministrador.last_page}
						onChange={handleChangePage}
						page={page}
					/>
					<IconButton
						style={{
							backgroundColor: 'white',
							boxShadow: '0px 0px 5px 0.7px grey',
						}}
						onClick={() => window.location.reload(false)}
					>
						<RefreshIcon></RefreshIcon>
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

export default ListaDeAdministradores;
*/
